<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }} </ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }} </ion-title>
        </ion-toolbar>
      </ion-header>
      
      <div id="container"  style="margin-left: 24px;margin-right: 24px;">
        <center>
          <signature :documento="documento"></signature>
        </center>
      </div>
      
    </ion-content>
  </ion-page>
</template>

<script>
import signature from './signature'
import { defineComponent } from 'vue';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { modalController,createAnimation } from '@ionic/vue';
import { mapGetters } from "vuex";
import axios from 'axios'
import Firmar from './firmar';
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'FolderPage',
  components: {
    signature,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return{
      axios,
      documentos : [],
      siniestro_id : null,
      siniestro : null
    }
  },
  mounted(){
    
    this.siniestro_id = this.$route.params.siniestro_id
    this.documento_id = this.$route.params.documento_id
    this.user_id = this.$route.params.user_id
   
    this.getSiniestro()
    this.getDocumentos()
    //this.getUser.role.name != 'Administrador' ? this.getColaboradoresByUser() : this.getColaboradores();
  },
  methods:{
    getDocumentos(){
      

       axios.get('/api/documentos/'+this.siniestro_id)
      .then(res => {
        console.log(res.data.data)
        this.documentos = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getColaboradoresByUser(){
       axios.get('/api/users/colaborador/byUser')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getSiniestro(){
      axios.get('/api/siniestros/'+this.siniestro_id)
       .then(res => {
         this.siniestro = res.data.data;
         console.log(this.siniestro)
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    page(url){
      axios.get(url)
       .then(res => {
         this.colaboradores = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    showClienteByColaborador(colaborador){
      this.$router.push({name : 'cliente_coloborador',params : {id:colaborador.id}})
    },
    download(documento){
      if (documento.documento_firmados.length == 0 ) {
        toast.openToast("Los cliente aun no han firmado los documentos","error",2000)
        return
      }

      for (var i = 0; i < documento.documento_firmados.length; i++) {
        //var fileLink = document.createElement('a');
        //fileLink.setAttribute('download', i+'_'+documento.nombre_documento);
        //fileLink.setAttribute('target', '_black');
        //fileLink.href =  axios.defaults.baseURL+'/'+documento.documento_firmados[i].pivot.path;
        window.open(axios.defaults.baseURL+'/'+documento.documento_firmados[i].pivot.path, "_blank"); 
        //document.body.appendChild(fileLink);
        //fileLink.click();
      }
    },
    async openModalFirma(documento) {
    
      const modal = await modalController
        .create({
          component: Firmar,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {documento : documento}
        })


   
      modal.present();
      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getColaboradoresByUser()
    this.getDocumentos()
      })

    },
     enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style scoped>

</style>
